import React from "react"

import "../style/bulma.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"


import Layout from "../components/layout"
import Learningmanagementsysteminternalbanner from "../components/internalpages/learning-management-system/learningmanagementsysteminternalbanner"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"

const Learningmanagement = () => (
  <Layout>
    <SEO title="Learning Management System" />
    <Learningmanagementsysteminternalbanner />
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Learningmanagement