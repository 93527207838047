import React, { Component } from 'react';
import PackagingC from "../../../images/screenshots/lmsscreen.png";
import Waterback from "../../../images/waterback.jpg";

const styles = {
    backbannertrain: {
        backgroundImage: `url(${Waterback})`
    }
};

class Learningmanagementinternalbanner extends Component {
    render() {

        return (
            <div>
                <section className="internalbanner" style={styles.backbannertrain}>
	<div className="overfunk"></div>
		<div className="container">
			<div className="columns is-marginless">
				<div className="column is-half">
					<p className="topsubtitle">Web-based portal</p>
					<h1 className="midsize">Learning Management System</h1>
					<p className="undersubtitle">centralise and manage learning in their organisation</p>
				</div>
			</div>
		</div>
	</section>
	<section className="under-internalbanner graddown">
		<div className="threeboxes">
			<div className="container">
				<div className="boxcard norise">
				<div className="columns is-marginless">
					<div className="column is-10">

							<h2>Learning Management System</h2><hr/>
							<p>The web-based application portal enables the clients to centralise and manage learning in their organisation</p><br/>
					</div>
				</div>
			

		<div className="columns is-marginless">
		<div className="column is-6 pictext">
			<h3>Centralise and manage learning</h3>
			<p className="bigpee">The portal is designed to allow the various client roles and packaging suppliers involved in the request process to: </p>
			<ul>
<li>User/Learner management </li>
<li>Group management</li>
<li>Module management</li>
<li>Certificate generation</li>
<li>Reports</li>
<li>Notifications</li>
<li>Multi Time zone and Multi Date Formats</li>
</ul>
		</div>
		<div className="column is-5 is-offset-1 pictext">
										<figure className="fullimage">
											<img src={PackagingC} alt="Tablet screenshot" />
										</figure>
									</div>
      </div>

</div>
			</div>
            </div>
			<div className="underitems">
<div className="overfunk"></div>
<div className="container">
  <div className="columns is-marginless">
  <div className="column is-8">
<p>Manage your <span>e-learning assets</span> with full control of scoring, testing and certification</p>
  </div>
    </div>
</div>
</div>

		</section>
            </div>
        );
    }
}

export default Learningmanagementinternalbanner;